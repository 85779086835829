@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(./Fonts/Nunito/Nunito-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 900;
    src: local('Nunito'), url(./Fonts/Nunito/Nunito-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    font-weight: 900;
    src: local('Nunito'), url(./Fonts/Nunito/Nunito-Black.ttf) format('truetype');
}